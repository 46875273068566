<template>
  <b-container class="mb-4">
    <div v-if="loading" class="text-center">
      <b-spinner label="Spinning"></b-spinner>
    </div>
    <b-jumbotron v-else>
      <template v-if="data">
        <h1 class="my-4">{{ data.title }}</h1>
        <div v-html="data.content" class="page-content" />
      </template>
      <template v-else>
        <h1 class="my-4">{{ $t('提示') }}</h1>
        <div class="page-content mb-5">找不到網頁<br />找不到网页<br />Page not found</div>
        <div class="page-content mb-5">
          您查詢的網頁不存在。 如果您在網址列輸入網址，請確定輸入正確。
          如果您由大豐銀行股份有限公司網頁連結至此，請記下該頁名稱並聯絡我們。<br />
          您查询的网页不存在。 如果您在网址列输入网址，请确定输入正确。
          如果您由大丰银行股份有限公司网页连结至此，请记下该页名称并联络我们。<br />
          This page you attempted to access does not exist on our servers. If you typed in the URL, please check to see
          if you typed the address correctly. If you are linked from somewhere within TaiFungBank Limited website,
          please make a note of which page contained the erroneous link and contact us.
        </div>
        <div>
          請於網址列輸入銀行網址 (<a href="https://www.taifungbank.com">https:// www.taifungbank.com</a>)
          直達本行網站。<br />
          请于网址列输入银行网址 (<a href="https://www.taifungbank.com">https:// www.taifungbank.com</a>)
          直达本行网站。<br />
          Please type in the Bank’s URL (<a href="https://www.taifungbank.com">https:// www.taifungbank.com</a>) to gain
          direct access to our website.
        </div>
        <!-- <div class="page-content mb-5" v-html="notFoundUrl"></div> -->
      </template>
    </b-jumbotron>
  </b-container>
</template>

<script>
import $ from 'jquery'
import { mapState } from 'vuex'
import { isCSR } from '@/utils'
import updateSeoInfo from '@/utils/meta'
import Lifecycle from '@/mixins/lifecycle'
import Router from 'vue-router'
if (isCSR()) {
  require('@/plugins/lightgallery')
}
export default {
  name: 'main-layout',
  data() {
    return {
      isMounted: false,
      loading: false,
      data: null,
      notFoundUrl: ''
    }
  },
  mixins: [Lifecycle],
  components: {},
  computed: {
    ...mapState('common', ['menu', 'page']),
    alreadyInited: function() {
      return !!this.menu
    }
  },
  serverPrefetch() {
    return this.fetchData()
  },
  seoInfo() {
    try {
      return {
        title: this.page.metaTitle,
        description: this.page.metaDescription || this.page.metaTitle,
        keywords: this.page.metaKeywords || this.page.metaTitle,
        metas: this.page.metaExtras
      }
    } catch (err) {}
  },
  mounted() {
    this.$bar.start()
    this.fetchData().finally(() => this.$bar.finish())
    this.isMounted = true
    this.notFoundUrl = this.$t('TEXT_NOT_FOUND_URL')
    // this.fetchData()
    this.$nextTick(() => {
      $(this.$el).on('click', '.page-content .collapse-group .card .title', function(e) {
        $(this)
          .parent('.card')
          .toggleClass('collapsed')
        $(this)
          .parent('.card')
          .find('.collapse')
          .toggle()
      }),
        $(this.$el).on('click', '.nav-tabs li', function(e) {
          const _index = $(this).index()
          $(this)
            .find('.nav-link')
            .addClass('active')
          $(this)
            .siblings()
            .find('.nav-link')
            .removeClass('active')
          $('.tab-content>div')
            .eq(_index)
            .addClass('active')
            .siblings()
            .removeClass('active')
        })
    })
  },
  watch: {
    // $route: {
    //   handler(n, o) {
    //     this.fetchData()
    //   },
    //   immediate: true
    // }
  },
  methods: {
    fetchData() {
      this.loading = true
      let path = this.$route.path.replace(`/${this.$route.params.lang}/`, '')
      path = path.replace(`preview/`, '')
      const cookies = this.$ssrContext && this.$ssrContext.cookies
      const { preview, id, vid } = this.$route.query
      return new Promise((resolve, reject) => {
        Promise.all([
          this.fetchInitData(),
          this.$store.dispatch('common/FETCH_PAGE', {
            path,
            cookies,
            preview,
            id,
            vid
          })
        ])
          .finally(() => {
            this.handleInit()
            resolve(updateSeoInfo.call(this))
          })
          .catch(reject)
      })
    },
    async handleInit() {
      if (await this.page) {
        if (this.page.url && this.isMounted) {
          window.location.href = this.page.url
        }
        this.data = this.page
        this.loading = false
        if (this.data && this.isMounted && this.$route.name == 'about-us') {
          this.$nextTick(() => {
            window.lightGallery(document.getElementById('lightgallery'), {
              subHtmlSelectorRelative: true,
              selector: '.item',
              download: false
            })
          })
        }
      } else {
        this.loading = false
      }
      // else {
      //   this.$router.push({
      //     name: 'home'
      //   })
      // }
      // $('body').delegate($('#tab_controls li'), 'click', function (e) {
      //   console.log();($(e))
      //   const _index = $(this).index()
      //   $(this)
      //     .find('.nav-link')
      //     .addClass('active')
      //   $(this)
      //     .siblings()
      //     .find('.nav-link')
      //     .removeClass('active')
      //   $('#tab_container>div')
      //     .eq(_index)
      //     .addClass('active')
      //     .siblings()
      //     .removeClass('active')
      // })
    }
  }
}
</script>
